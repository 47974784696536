<template>
  <!-- 任务详情页 -->
  <div class="mission_details" v-loading="loading">
    <!-- 左边列表页 -->
    <div class="task_list_left">
      <!-- 分组依据 -->
      <div class="grouping">
        <!-- 按日期分组 -->
        <span>{{ $t("label.projectManagement.groupbydate") }}</span>
      </div>
      <!-- 任务列表数据 -->
      <div class="task_list_data">
        <div class="no_data_available" v-show="false">
          <!-- 暂无数据 -->
          {{ $t("vue_label_SMS_nodata") }}
        </div>
        <ul
          class="exhibition_list"
          v-for="(item, index) in listDisplayData1"
          :key="index"
        >
          <li
            class="task_information"
            @click="getdata(item, index)"
            style="cursor: pointer"
            :class="{ dataStyle: index == selectBackgroundColor }"
          >
            <!-- 时间 -->
            <div class="time_charging">
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-task"></use>
              </svg>

              <div class="content">
                <span class="time"
                  >{{ item.actual_working_hours }}hrs</span
                >
                <!-- 是 -->
                <span
                  class="file"
                  v-if="item.settlement_type == $t('label.tabpage.yesz')"
                  style="color: green"
                >
                  <!-- 计费 -->
                  {{ $t("label.projectManagement.charging") }}
                </span>
                <!-- 否 -->
                <span
                  class="file"
                  v-if="item.settlement_type == $t('label.tabpage.noz')"
                  style="color: red"
                >
                  <!-- 不计费 -->
                  {{ $t("label.projectManagement.nocharge") }}
                </span>
                <!-- <span class="charging">{{ ruleForm.settlement }}</span> -->
              </div>
            </div>
            <!-- 任务名称 -->
            <div class="subtask_name_box">
              <span>{{ item.task_or_problem }}</span>
            </div>
            <!-- 内容 -->
            <div class="con_text">
              <span>{{ item.member }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 右边详情页 -->
    <div class="details_page_right">
      <!-- 顶部详情标题模块 -->
      <div class="details_task_name">
        <!-- 任务名称 -->
        <div class="details_title_box">
          <div class="details_name">{{ taskOrProblem }}</div>
          <div class="icon_img">
            <i class="el-icon-delete" @click.prevent="deletedata"></i>
            <!-- 差号点击返回上一页 -->
            <i class="el-icon-close" @click.prevent="backlist"></i>
          </div>
        </div>
        <!-- 详情内容 -->
        <div class="display_content">
          <div class="head_text">
            <div class="imgs">
               <span @click="toDetail(ruleForm.project_name)" class="line-right ruleproject">
                  <svg aria-hidden="true" class="icon">
                    <use xlink:href="#icon-xiangmu"></use>
                  </svg>
                    {{ ruleForm.projectName }}
              </span>
              <span class="line-right">
                <svg aria-hidden="true" class="icon">
                  <use xlink:href="#icon-head-portrait"></use>
                </svg>
                {{ ruleForm.memberName }}
              </span>
              <span>
                <el-popover
                  placement="bottom-start"
                  title=""
                  width="250"
                  trigger="click"
                >
                  <div>
                    <p>
                      <span> {{ $t("label.createdate") }}</span
                      >:{{ ruleForm.createdate }}
                    </p>
                    <p>
                      <span>{{
                        $t("front-template-V1-Modification-time")
                      }}</span
                      >:{{ ruleForm.lastmodifydate }}
                    </p>
                  </div>
                  <svg
                    aria-hidden="true"
                    class="icon xinxiIcon"
                    slot="reference"
                  >
                    <use xlink:href="#icon-xinxi"></use>
                  </svg>
                </el-popover>
              </span>
            </div>
            <div class="right_arrow_img" v-show="rightArrow">
              <svg aria-hidden="true" class="icon" @click="prevdata">
                <use xlink:href="#icon-qianjin"></use>
              </svg>
              <svg aria-hidden="true" class="icon" @click="nextdata">
                <use xlink:href="#icon-back-off"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <!-- 中间时间，计费，日期模块 -->
      <div class="working_hours_charging_date">
        <div class="middle_box">
          <!-- 任务时间 -->
          <div class="working_hours_left">
            <div class="img_duration">
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-time-img"></use>
              </svg>
            </div>
            <div class="all_the_time">
              <!-- 实际工作时长 -->
              <!-- 报工工时 -->
              <div class="journal">
                {{ $t("front-project-module-v1-actual_working_hours") }}
              </div>
              <div class="hours_time">
                <span class="hours">{{ ruleForm.actual_working_hours }}</span>
                <!-- 小时 -->
                <span class="hour_text">{{ $t("label.ems.hour") }}</span>
              </div>
            </div>
          </div>
          <!-- 计费，日期 -->
          <div class="billing_date_right">
            <!-- 计费 -->
            <div class="billing_information">
              <div class="gol-coin">
                <svg aria-hidden="true" class="icon">
                  <use xlink:href="#icon-gol-coin"></use>
                </svg>
              </div>
              <div class="billing_text">
                <!-- 计费 -->
                <div class="charge_type">
                  {{ $t("label.projectManagement.charging") }}
                </div>
                <div class="charge_text">{{ ruleForm.settlement_type }}</div>
                <!-- <div class="charge_text" v-else>不计费</div> -->
              </div>
            </div>
            <!-- 日期 -->
            <div class="date_information">
              <div class="date-img">
                <svg aria-hidden="true" class="icon">
                  <use xlink:href="#icon-date-img"></use>
                </svg>
              </div>
              <div class="date_time">
                <!-- 日期 -->
                <div class="date_text">{{ $t("date") }}</div>
                <div class="time_text">{{ ruleForm.duedate }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 底部时间表信息 -->
      <div class="schedule_information">
        <!-- 时间表信息 -->
        <div class="time_information_table_box">
          {{ $t("label.projectManagement.Scheduleinformation") }}
        </div>
        <div class="information_details_data">
          <!-- 详细信息 -->
          <div class="detailed_tasks">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
              :label-position="labelPosition"
              size="mini"
              :disabled="!isEdit"
            >
              <div class="top_left_right">
                <!-- 左边 -->
                <div class="form_box_left">
                  <div class="details_date_box">
                    <!-- 日期 -->
                    <el-form-item :label="$t('date')" required>
                      <el-col :span="11"  v-loading="formitemloading[0]">
                        <el-form-item prop="duedate">
                          <!-- 选择日期 -->
                          <el-date-picker
                            @change="selectDate(ruleForm.duedate)"
                            type="date"
                            :placeholder="$t('label.tabpage.selectdate')"
                            v-model="ruleForm.duedate"
                            style="width: 100%"
                            :picker-options="pickerOptions"
                          ></el-date-picker>
                        </el-form-item>
                      </el-col>
                    </el-form-item>
                    <div class="dividing_line"></div>
                  </div>
                  <div class="actual_working_hours">
                    <!-- 实际工作时长 -->
                    <el-form-item
                      :label="
                        $t('front-project-module-v1-actual_working_hours') +
                        '（h）'
                      "
                      prop="actual_working_hours"
                      size="mini"
                    >
                      <!-- oninput="value=value.replace(/[^\d]/g,'')" -->
                      <el-input
                        v-loading="formitemloading[1]"
                        v-model="ruleForm.actual_working_hours"
                        @focus="actualWorkingHours"
                        @blur="actualWorkingH"
                        v-on:input="myFunction($event)"
                      ></el-input>
                    </el-form-item>
                    <div class="line"></div>
                    <!-- 创建者 -->
                    <el-form-item
                      :label="$t('label.projectManagement.creator')"
                      prop="createbyname"
                      size="mini"
                    >
                      <el-input
                        v-model="ruleForm.createbyname"
                        disabled
                      ></el-input>
                    </el-form-item>
                    <div class="line"></div>
                  </div>
                </div>
                <!-- 右边 -->
                <div class="form_box_right">
                  <!-- 成员 -->
                  <el-form-item
                    :label="$t('label.marketseamemset.user')"
                    prop="memberName"
                    size="mini"
                   
                  >
                    <!-- <el-input
                      v-model="ruleForm.memberName"
                      @focus="getMember"
                    ></el-input>   -->
                   
                    <el-popover
                      placement="top"
                      width="460"
                      trigger="click"
                      ref="userelpopver"
                      @show="getuserlist"
                      >
                      <div  class='userpopver'>
                        <el-input v-model="username"  
                          @keyup.enter.native="getusers()"
                          prefix-icon="el-icon-search"></el-input>
                        <ul v-if="useroptions&&useroptions.length>0">
                          <li v-for="(item,index) in useroptions" :key='index'  :class="item.name==ruleForm.memberName ?'backli':''" @click="userchange(item)">
                            <svg aria-hidden="true" class="icon">
                              <use href="#icon-console_case_contactsLoge"></use>
                            </svg>
                            {{item.name}}
                          </li>
                        </ul>
                        <p v-else class='nodeta'>{{$t('vue_label_SMS_nodata')}}</p>
                      </div>
                      <div class="userinput" slot="reference">
                        <svg aria-hidden="true" class="icon">
                              <use href="#icon-console_case_contactsLoge"></use>
                        </svg>
                        <el-input
                          v-loading="formitemloading[2]"
                          v-model="ruleForm.memberName"
                        ></el-input> 
                      </div> 
                    </el-popover>
                  </el-form-item>
                  <div class="line"></div>
                  <!-- 结算类型 -->
                  <!-- 计费 -->
                  <el-form-item
                    :label="$t('label.projectManagement.charging')"
                    prop="settlement_type"
                    size="mini"
                    v-popover:popover
                  >
                    <el-input
                      v-loading="formitemloading[3]"
                      v-model="ruleForm.settlement_type"
                      @focus="selectBillingEvent"
                    ></el-input>
                  </el-form-item>
                  <div class="line"></div>
                  <el-popover
                    ref="popover"
                    placement="bottom-start"
                    width="200"
                    trigger="click"
                    popper-class="chargingPopover"
                    v-model="visible"
                  >
                    <!-- <div class="billing_style" v-show="isShowBillingBox"> -->
                    <div class="select_billing" @click="selectBillingChange">
                      <!-- 是 -->
                      {{ $t("label.tabpage.yesz") }}
                    </div>
                    <!-- 否 -->
                    <div class="no_charge" @click="noChargeChange">
                      {{ $t("label.tabpage.noz") }}
                    </div>
                    <!-- </div> -->
                  </el-popover>
                </div>
              </div>
            </el-form>
            <div class="remarks">
              <div class="remarks_record">
                <!-- 工作总结 -->
                <div class="remarks_text">
                  {{ $t("c385") }}
                </div>
                <div class="textarea_box">
                  <!-- 请输入内容 -->
                  <el-input
                    v-loading="formitemloading[4]"
                    type="textarea"
                    :rows="2"
                    :placeholder="$t('label.tabpage.contentz')"
                    v-model="ruleForm.remarks"
                    @focus="inputRemarkEvent"
                    :disabled="!isEdit"
                    @blur="inputblurEvent"
                  ></el-input>
                </div>
              
              </div>
            </div>
          </div>
          <!-- 按钮 -->
          <!-- 暂时不用这个div -->
          <div class="details_btn" v-show="false">
            <div class="btn">
              <!-- 取消 -->
              <el-button size="mini" @click="cancel">{{
                $t("label.chatter.cancel")
              }}</el-button>
              <!-- 确定 -->
              <el-button
                type="primary"
                size="mini"
                @click="confirmSave('ruleForm')"
                >{{ $t("label.chatter.ok") }}</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 成员搜索弹框 -->
    <!-- 查找/查找多选 -->
    <el-dialog
      :title="$t('label.searchs')"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="beforeClose"
      append-to-body
    >
      <div
        :style="{
          height: dialogBodyHeight,
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <search-table
          ref="searchTable"
          :fieldId="fieldId"
          :checked="checked"
          :relevant-objid="relevantObjId"
          :relevant-prefix="relevantPrefix"
          :projectId="projectId"
          @changeSelect="changeSelect"
          @setFieldReltaion="setFieldReltaion"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 实际工作清单详情页
 */
import * as actualworklistApi from "./api.js";
import * as CommonObjApi from "./api";
import SearchTable from "@/components/Form/search-table.vue"; //成员搜索弹框

export default {
  components: {
    SearchTable,
  },
  data() {
    return {
      projectTasksId: "", //成员弹框网格需要的id
      loading: false,
      visible: false, //计费弹框显示隐藏
      taskDetailListData: {}, //任务详情列表数据，取消按钮时用到
      isShowButton: false, //确认取消按钮的显示隐藏
      // 左右箭头的显示和隐藏
      // leftArrow: true,
      rightArrow: true,
      confirmId: "",
      // 点击确认后匹配数据的id
      matchingDataId: "",
      // 项目id
      projectId: "",
      // 是否显示计费弹框
      isShowBillingBox: false,
      // 列表选中后背景色
      selectBackgroundColor: null,
      // 弹框
      showSearchTable: false,
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      fieldId: "ffe2020Milestone06",
      checked: false,
      relevantPrefix: "p11",
      relevantObjId: "cloudccprojectmember",
      // 备注绑定的值
      // textarea: "",
      // options: [
      //   {
      //     value: this.$i18n.t("label.tabpage.yesz"), //是
      //     label: this.$i18n.t("label.projectManagement.charging"), //计费
      //   },
      //   {
      //     value: this.$i18n.t("label.tabpage.noz"), //否
      //     label: this.$i18n.t("label.projectManagement.nocharge"), //不计费
      //   },
      // ],
      projectName: "",
      // 列表展示数据
      listDisplayData: [],
      listDisplayData1: [],

      // 顶部对齐
      labelPosition: "top",
      // 日期需要的参数
      ruleForm: {
        id: "",
        project_name: "",
        taskOrProblem: "",
        duedate: "", //日期
        actual_working_hours: "",
        createbyname: "",
        memberName: "",
        member: "",
        settlement_type: "",
        remarks: "",
        projectName: "",
        createdate: "",
        lastmodifydate: "",
      },
      // 详细信息检验
      // duedate  actual_working_hours settlement_type
      rules: {
        duedate: [
          {
            required: true,
            message: this.$i18n.t("label.projectManagement.pleaseselectadate"), //请选择日期
            trigger: "change",
          },
        ],
        actual_working_hours: [
          {
            required: true,
            message: this.$i18n.t(
              "front-project-module-v1-actual_working_hours"
            ),
            trigger: "blur",
          }, //请输入实际工作时长
        ],
        createbyname: [
          {
            required: true,
            message: this.$i18n.t("label.projectManagement.PleaseenterCreator"),
            trigger: "blur",
          }, //请输入创建者
        ],
        memberName: [
          {
            required: true,
            message: this.$i18n.t("label.projectManagement.Pleaseenteramember"),
            trigger: "blur",
          }, //请输入成员
        ],
        settlement_type: [
          {
            required: true,
            message: this.$i18n.t(
              "label.projectManagement.Pleaseentersettlementtype"
            ),
            trigger: "blur",
          }, //请输入结算类型
        ],
      },
      isGoProjectDetial: false,
      taskOrProblem: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      isEdit: null, //编辑权限
      workingtimelong:false,//实际工作时长聚焦
      worktotal:false,//工作总结的聚焦
      username:'',//用户搜索的
      useroptions:[],//成员列表
      // userpop:false,//用户搜索弹框
      isSLookup:'',//true为多条件搜索，false为单条件搜索
      worktiemchange:false,//报工工时是否改动
      remarkschange:false,//工作总结值的改变
      formitemloading:[false,false,false,false,false]
    };
  },
  // 获取上一个路由
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.fullPath === "/actualworklist/listviews") {
        vm.isGoProjectDetial = true;
      }
    });
  },
  created() {
    // this.selectBackgroundColor = 0;
  },
  computed:{
    newruleForm(){
        return JSON.parse(JSON.stringify(this.ruleForm));
      }
  },
  watch:{
    newruleForm:{
      deep:true,
      handler(newName,oldName) {
        if(newName.actual_working_hours!==oldName.actual_working_hours &&newName.id==oldName.id){
          this.worktiemchange=true;
        }else{
          this.worktiemchange=false;
        }
          if(newName.remarks!==oldName.remarks &&newName.id==oldName.id){
          this.remarkschange=true;
        }else{
          this.remarkschange=false;
        }
      },
      
    },
  },
  methods: {
    //编辑权限
    getperm() {
      let params = {
        id: this.ruleForm.id,
      };
      actualworklistApi.getPermissionById(params).then((res) => {
        if (res.result) {
          this.isEdit = res.data.isEdit;
        }
      });
    },
    //选择修改用户
    userchange(item){
      this.$refs.userelpopver.doClose();
      this.ruleForm.memberName=item.name;
      this.ruleForm.member=item.id;
      this.formitemloading[2]=true
      this.confirmSave('ruleForm',2)
    },
    //获取查找成员列表信息
    getuserlist(){
      let params={
        fieldId:this.fieldId,
        objId:this.relevantObjId,
        prefix:this.relevantPrefix,
      }
      CommonObjApi.getLookupInfo(params).then((res)=>{
        if(res.result){
          this.isSLookup=res.data.isSLookup;
          this.getusers();
        }
      })
    },
    //获取成员列表数据
    getusers(){
      let params={
        fieldId:this.fieldId,
        filterConditionVals:"",
        isKnowledge:"",
        isSLookup:this.isSLookup,
        modelid:"",
        objId:this.relevantObjId,
        objconid:"",
        page:1,
        pageSize:20,
        parentId:this.ruleForm.taskOrProblem,
        prefix:this.relevantPrefix,
        projectId:this.ruleForm.project_name,
        searchConditions:"",
        searchKeyWord:this.username,
        selectedValue:""
      }
       CommonObjApi.getLookupData(params).then((res)=>{
         this.useroptions=res.data.dataList
       })
    },
    // 点击成员出现弹框
    // getMember() {
    //   if (this.$route.query.state === "1") {
    //     this.projectId = this.$route.query.row.projectId;
    //   } else if (this.$route.query.state == "2") {
    //     this.projectId = this.projectTasksId;
    //   }
    //   this.isShowButton = true;
    //   this.showSearchTable = true;
    //   this.$nextTick(() => {
    //     this.$refs.searchTable.page = 1;
    //     this.$refs.searchTable.pageObj.dataList = [];
    //     this.$refs.searchTable.searchConditions = "";
    //     this.$refs.searchTable.init();
    //   });
    // },
    // 选择计费事件出现计费弹框
    selectBillingEvent() {
      this.isShowBillingBox = true;
      this.isShowButton = true;
    },
    //取消按钮
    cancel() {
      if (!this.$route.query.row) {
        this.backlist();
        return;
      }
      if (this.$route.query.row.id === this.ruleForm.id) {
        this.ruleForm.duedate = this.$route.query.row.pdate; //日期
        this.ruleForm.memberName = this.$route.query.row.member; //成员
        this.ruleForm.actual_working_hours =
          this.$route.query.row.actual_working_hours; //实际工作时长
        this.ruleForm.settlement_type = this.$route.query.row.settlement_type; //结算类型
        this.ruleForm.createbyname = this.$route.query.row.createbyname; //创建者
        this.ruleForm.remarks = this.$route.query.row.remarks; //备注
      } else if (this.$route.query.row.id !== this.ruleForm.id) {
        this.ruleForm.duedate = this.taskDetailListData.pdate; //日期
        this.ruleForm.memberName = this.taskDetailListData.member; //成员
        this.ruleForm.actual_working_hours =
          this.taskDetailListData.actual_working_hours; //实际工作时长
        this.ruleForm.settlement_type = this.taskDetailListData.settlement_type; //结算类型
        this.ruleForm.createbyname = this.taskDetailListData.createbyname; //创建者
        this.ruleForm.remarks = this.taskDetailListData.remarks; //备注
      }
      this.backlist();
    },
    // 向左
    prevdata() {
      for (let i = 0; i < this.listDisplayData1.length; i++) {
        if (this.listDisplayData1[i].id == this.ruleForm.id) {
          if (this.listDisplayData1[i - 1] !== undefined) {
            //  给对应的列表加颜色
            if (this.selectBackgroundColor !== 0) {
              this.selectBackgroundColor = this.selectBackgroundColor - 1;
            }
            this.ruleForm.taskOrProblem =
              this.listDisplayData1[i - 1].taskOrProblem;
            this.ruleForm.id = this.listDisplayData1[i - 1].id;
            this.projectName = this.listDisplayData1[i - 1].projectName;
            this.ruleForm.remarks = this.listDisplayData1[i - 1].remarks;
            this.ruleForm.duedate = this.listDisplayData1[i - 1].pdate;
            this.ruleForm.actual_working_hours =
              this.listDisplayData1[i - 1].actual_working_hours;
            this.ruleForm.createbyname =
              this.listDisplayData1[i - 1].createbyname;
            this.ruleForm.memberName = this.listDisplayData1[i - 1].member;
            this.ruleForm.member = this.listDisplayData1[i - 1].judegm;
            this.ruleForm.settlement_type =
              this.listDisplayData1[i - 1].settlement_type;
            this.ruleForm.projectDataType =
              this.listDisplayData1[i - 1].subtaskId == null
                ? this.listDisplayData1[i - 1].theirProblem == null
                  ? "1"
                  : "3"
                : "2";
            this.ruleForm.name = "";
            this.taskOrProblem = this.listDisplayData1[i - 1].task_or_problem;
            this.projectTasksId = this.listDisplayData1[i - 1].projectId;
            return;
          } else {
            // 已经是第一条了！
            this.$message({
              type: "warning",
              message: this.$i18n.t("label.projectManagement.thefirstone"),
            });
            this.selectBackgroundColor = 0;
          }
        }
      }
    },
    // 向右
    nextdata() {
      for (let i = 0; i < this.listDisplayData1.length; i++) {
        if (this.listDisplayData1[i].id == this.ruleForm.id) {
          if (this.listDisplayData1[i + 1] !== undefined) {
            if (this.selectBackgroundColor !== this.listDisplayData1.length) {
              this.selectBackgroundColor = this.selectBackgroundColor + 1;
            }
            this.ruleForm.taskOrProblem =
              this.listDisplayData1[i + 1].taskOrProblem;
            this.ruleForm.id = this.listDisplayData1[i + 1].id;
            this.projectName = this.listDisplayData1[i + 1].projectName;
            this.ruleForm.remarks = this.listDisplayData1[i + 1].remarks;
            this.ruleForm.duedate = this.listDisplayData1[i + 1].pdate;
            this.ruleForm.actual_working_hours =
              this.listDisplayData1[i + 1].actual_working_hours;
            this.ruleForm.createbyname =
              this.listDisplayData1[i + 1].createbyname;
            this.ruleForm.memberName = this.listDisplayData1[i + 1].member;
            this.ruleForm.member = this.listDisplayData1[i + 1].judegm;
            this.ruleForm.settlement_type =
              this.listDisplayData1[i + 1].settlement_type;
            this.ruleForm.projectDataType =
              this.listDisplayData1[i + 1].subtaskId == null
                ? this.listDisplayData1[i + 1].theirProblem == null
                  ? "1"
                  : "3"
                : "2";
            this.ruleForm.name = "";
            this.taskOrProblem = this.listDisplayData1[i + 1].task_or_problem;
            this.projectTasksId = this.listDisplayData1[i + 1].projectId;
            return;
          } else {
            // 已经是最后一条了！
            this.$message({
              type: "warning",
              message: this.$i18n.t("label.projectManagement.thelastone"),
            });
          }
        }
      }
    },
    deletedata() {
      let params = {
        id: this.ruleForm.id,
        objectApi: "CloudccProjectActualWorkList",
      };
      CommonObjApi.getPermissionById({ id: this.ruleForm.id }).then((res) => {
        if (res.data.isDelete) {
          if (this.listDisplayData1.length === 1) {
            // 已经是最后一条数据，删除后将返回上一页           删除提示
            this.$alert(
              this.$i18n.t("label.projectManagement.delete.return"),
              this.$i18n.t("label.projectManagement.delete.prompt"),
              {
                confirmButtonText: this.$i18n.t("label.chatter.ok"), //确定
                callback: (action) => {
                  this.$message({
                    type: "info",
                    message: `action: ${action}`,
                  });
                },
              }
            );
          }

          if (this.listDisplayData1.length > 1) {
            // 确认删除该条数据吗      取消
            this.$confirm(
              this.$i18n.t("label.weixin.confirm.delete") + "？",
              this.$i18n.t("pagecreator.page.button.delete"), //删除
              {
                confirmButtonText: this.$i18n.t("label.chatter.ok"), //确认
                cancelButtonText: this.$i18n.t("label.chatter.cancel"), //取消
                type: "warning",
              }
            )
              .then(() => {
                CommonObjApi.del(params).then((res) => {
                  if (res.returnCode == "1") {
                    this.$message({
                      message: this.$i18n.t("label.ems.delete.success"), //删除成功
                      type: "success",
                    });
                    for (let i = 0; i < this.listDisplayData1.length; i++) {
                      if (this.listDisplayData1[i].id == this.ruleForm.id) {
                        if (this.listDisplayData1[i + 1]) {
                          this.ruleForm.taskOrProblem =
                            this.listDisplayData1[i + 1].taskOrProblem;
                          this.ruleForm.id = this.listDisplayData1[i + 1].id;
                          this.projectName =
                            this.listDisplayData1[i + 1].projectName;
                          this.ruleForm.remarks =
                            this.listDisplayData1[i + 1].remarks;
                          this.ruleForm.duedate =
                            this.listDisplayData1[i + 1].pdate;
                          this.ruleForm.actual_working_hours =
                            this.listDisplayData1[i + 1].actual_working_hours;
                          this.ruleForm.createbyname =
                            this.listDisplayData1[i + 1].createbyname;
                          // this.ruleForm.taskOrProblem = this.listDisplayData1[
                          //   i + 1
                          // ].relateSubtasks;
                          this.ruleForm.memberName =
                            this.listDisplayData1[i + 1].member;
                          this.ruleForm.member =
                            this.listDisplayData1[i + 1].judegm;
                          this.ruleForm.settlement_type =
                            this.listDisplayData1[i + 1].settlement_type;
                          this.ruleForm.projectDataType =
                            this.listDisplayData1[i + 1].subtaskId == null
                              ? this.listDisplayData1[i + 1].theirProblem ==
                                null
                                ? "1"
                                : "3"
                              : "2";
                          this.ruleForm.name = "";
                          this.taskOrProblem =
                            this.listDisplayData1[i + 1].task_or_problem;
                          this.projectTasksId =
                            this.listDisplayData1[i + 1].projectId;
                          this.findTimeBucket();
                          return;
                        } else {
                          // if (this.listDisplayData1[i - 1] !== undefined) {
                          this.ruleForm.taskOrProblem =
                            this.listDisplayData1[i - 1].taskOrProblem;
                          this.ruleForm.id = this.listDisplayData1[i - 1].id;
                          this.projectName =
                            this.listDisplayData1[i - 1].projectName;
                          this.ruleForm.remarks =
                            this.listDisplayData1[i - 1].remarks;
                          this.ruleForm.duedate =
                            this.listDisplayData1[i - 1].pdate;
                          this.ruleForm.actual_working_hours =
                            this.listDisplayData1[i - 1].actual_working_hours;
                          this.ruleForm.createbyname =
                            this.listDisplayData1[i - 1].createbyname;
                          // this.ruleForm.taskOrProblem = this.listDisplayData1[
                          //   i - 1
                          // ].relateSubtasks;
                          this.ruleForm.memberName =
                            this.listDisplayData1[i - 1].member;
                          this.ruleForm.member =
                            this.listDisplayData1[i - 1].judegm;
                          this.ruleForm.settlement_type =
                            this.listDisplayData1[i - 1].settlement_type;
                          this.ruleForm.projectDataType =
                            this.listDisplayData1[i - 1].subtaskId == null
                              ? this.listDisplayData1[i - 1].theirProblem ==
                                null
                                ? "1"
                                : "3"
                              : "2";
                          this.ruleForm.name = "";
                          this.taskOrProblem =
                            this.listDisplayData1[i - 1].task_or_problem;
                          this.projectTasksId =
                            this.listDisplayData1[i - 1].projectId;
                          this.findTimeBucket();
                          return;
                          // }
                        }
                      }
                    }
                  }
                  // this.findTimeBucket();
                });
              })
              .catch(() => {});
          } else if (this.listDisplayData1.length === 1) {
            // 已经是最后一条数据了，删除后将返回上一页
            this.$confirm(
              this.$i18n.t("label.projectManagement.delete.return"),
              this.$i18n.t("label.projectManagement.delete.prompt"), //删除提示
              {
                confirmButtonText: this.$i18n.t("label.chatter.ok"), //确认
                cancelButtonText: this.$i18n.t("label.chatter.cancel"), //取消
                type: "warning",
              }
            )
              .then(() => {
                CommonObjApi.del(params).then((res) => {
                  if (res.returnCode == "1") {
                    this.$router.go(-1);
                  }
                });
              })
              .catch(() => {});
          }
        }
      });
    },
    backlist() {
      // 计算两个日期相差多少天
      // let timeValue =
      //   (new Date(this.$route.query.endDate) -
      //     new Date(this.$route.query.startDate).getTime()) /
      //   1000 /
      //   60 /
      //   60 /
      //   24;
      // let dataIdentification
      // if (timeValue === 0) {
      //   dataIdentification='data'
      // } else if (timeValue === 6) {
      //   dataIdentification='week'
      // } else if (timeValue > 6){
      //   dataIdentification='month'
      // }else{
      //   dataIdentification=''
      // }
      // 
      this.$router.push({
        path: `/actualworklist/listviews`,
        // query: {
        //     optionValue:this.$route.query.state,
        //     endDatejiesu:this.$route.query.endDate,
        //     startDatekiasi:this.$route.query.startDate,
        //     dataIdentification:dataIdentification
        //   },
      });
    },
    getdata(data, index) {
      this.isShowButton = false;
      this.taskDetailListData = data;
      // this.ruleForm.judegm = data.judegm; //成员id
      // this.ruleForm.createbyid = data.createbyid; //创建人id
      this.ruleForm.project_name = data.projectId; //项目id
      // this.ruleForm.taskOrProblem = data.task_or_problem;
      this.ruleForm.taskOrProblem = data.taskOrProblem;
      this.selectBackgroundColor = index; //选中后加背景色
      this.ruleForm.id = data.id;
      this.projectName = data.projectName;
      this.ruleForm.remarks = data.remarks; //备注
      this.ruleForm.duedate = data.pdate; //日期
      this.ruleForm.actual_working_hours = data.actual_working_hours; //实际工作时长
      this.ruleForm.createbyname = data.createbyname; //创建者
      this.ruleForm.memberName = data.member; //成员
      this.ruleForm.member = data.judegm;
      this.ruleForm.settlement_type = data.settlement_type; //结算类型
      this.projectTasksId = data.projectId;
      this.ruleForm.projectName = data.projectName;
      this.ruleForm.createdate = data.createdate;
      this.ruleForm.lastmodifydate = data.lastmodifydate;
      this.ruleForm.projectDataType =
        data.subtaskId == null ? (data.theirProblem == null ? "1" : "3") : "2";
      this.ruleForm.name = "";
      this.taskOrProblem = data.task_or_problem;
      this.getperm();
    },
    // 确认按钮
    confirmSave(ruleForm,indexx) {
      if (this.ruleForm.remarks === null) {
        this.ruleForm.remarks = "";
      }
      // this.ruleForm.settlement_type = this.ruleForm.settlement_type;
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          // this.loading = true;
          // this.ruleForm.createbyname = "";
          // this.ruleForm.memberName = "";
          let params = {
            data: JSON.stringify([this.ruleForm]),
            marketsea: false,
            objectApi: "CloudccProjectActualWorkList",
          };
          CommonObjApi.save(params).then((res) => {
            // this.loading = false;
            if (res.returnCode == "1" && res.data[0].isSaveSuccess === "true") {
              this.$message({
                message: this.$i18n.t("label.search.saveok"), //保存成功
                type: "success",
              });
              this.matchingDataId = this.ruleForm.id;
              this.confirmId = "confirm";
              // this.findTimeBucket(true);
              this.listDisplayData1.map((items) => {
                if (this.ruleForm.id === items.id) {
                  items.remarks = this.ruleForm.remarks; //备注
                  items.pdate = this.ruleForm.duedate; //日期
                  items.actual_working_hours =
                    this.ruleForm.actual_working_hours; //实际工作时长
                  items.createbyname = this.ruleForm.createbyname; //创建者
                  items.member = this.ruleForm.memberName; //成员
                  items.settlement_type = this.ruleForm.settlement_type; //结算类型
                  return items;
                }
              });
            } else {
              // 查找字段保存时，存在筛选条件不符，页面定位指定字段，并给出提示
              if(res?.data && res.data[0]?.errormessage.includes('illegal_field_apiname')){
                // 值不存在或与筛选条件不匹配
                this.$message.error(this.$i18n.t("c2420"));
              }else{
                this.$message.error(
                  res.data[0].errormessage || this.$i18n.t("chatter_save_f")
                );
              }
            }
            setTimeout(()=>{
              this.$set(this.formitemloading,indexx,false)
            },200)
          });
        }
      });
    },
    //请求左边列表数据
    findTimeBucket() {
      this.loading = true;
      this.listDisplayData = [];
      this.listDisplayData1 = [];
      let params = {
        startDate: this.$route.query.startDate,
        endDate: this.$route.query.endDate,
      };
      actualworklistApi.findTimeBucket(params).then((res) => {
        if (res.returnCode == "1") {
          this.loading = false;
          //请求成功
          for (let i in res.data.data) {
            res.data.data[i].id = i;
          }
          for (let i in res.data.data)
            this.listDisplayData = [
              ...this.listDisplayData,
              res.data.data[i].workerList,
            ];
        }
        this.listDisplayData1 = this.listDisplayData.reduce(function (a, b) {
          return a.concat(b);
        });
        // this.getdata(this.listDisplayData1[0],0)
        if (this.$route.query.state == "2") {
          this.loading = false;
          this.selectBackgroundColor = 0; //是网格的话默认给第一个加背景色
          this.ruleForm.project_name = this.listDisplayData1[0].projectId;
          //网格
          this.ruleForm.id = this.listDisplayData1[0].id;
          this.projectTasksId = this.listDisplayData1[0].projectId;
          this.projectName = this.listDisplayData1[0].projectName;
          this.ruleForm.remarks = this.listDisplayData1[0].remarks;
          this.ruleForm.duedate = this.listDisplayData1[0].pdate; //日期
          this.ruleForm.actual_working_hours =
            this.listDisplayData1[0].actual_working_hours; //实际工作时长
          this.ruleForm.createbyname = this.listDisplayData1[0].createbyname; //创建者
          this.ruleForm.memberName = this.listDisplayData1[0].member; //成员
          this.ruleForm.member = this.listDisplayData1[0].judegm;
          this.ruleForm.settlement_type =
            this.listDisplayData1[0].settlement_type; //结算类型
          this.ruleForm.taskOrProblem = this.listDisplayData1[0].taskOrProblem;
          this.ruleForm.projectName = this.listDisplayData1[0].projectName;
          this.ruleForm.projectDataType =
            this.listDisplayData1[0].subtaskId == null
              ? this.listDisplayData1[0].theirProblem == null
                ? "1"
                : "3"
              : "2";
          this.ruleForm.name = "";
          this.taskOrProblem = this.listDisplayData1[0].task_or_problem;
        }

        // 判断id给对应的选项加背景颜色
        if (this.confirmId == "") {
          this.listDisplayData1.map((items, indexs) => {
            // 
            if (
              // items.id === this.$route.query.row.id ||
              items.id === this.ruleForm.id
            ) {
              this.selectBackgroundColor = indexs;
            }
          });
        } else if (this.confirmId == "confirm") {
          this.listDisplayData1.map((items, indexs) => {
            if (
              items.id === this.matchingDataId ||
              items.id === this.ruleForm.id
            ) {
              this.selectBackgroundColor = indexs;
              this.confirmId == "";
              this.ruleForm.createbyname = items.createbyname;
              this.ruleForm.memberName = items.member;
              this.ruleForm.settlement_type;
            }
          });
        }
      });
    },
    beforeClose() {
      this.showSearchTable = false;
    },
    // 点击前往精准搜索--选中弹出窗中的数据
    changeSelect(row) {
      this.ruleForm.member = row.data.id;
      this.ruleForm.memberName = row.data.name;
      this.showSearchTable = false;
      // let options=[{
      //   label: row.data.name,
      //   value: row.data.id,
      // }];
      // this.$set(this.tableData[this.curRow][this.curColum], 'optionList', options);  //下拉数据
      // this.$set(this.tableData[this.curRow][this.curColum], this.curProp, row.data.id); //给绑定的值赋值
      // this.$set(this.formData[this.curRow], this.curProp, row.data.id);  //保存当前行的项目id
    },
    // 设置查找筛选条件
    setFieldReltaion(filterFieldIds) {
      let values = [];
      values.push(this.id);
      for (let reltaion in filterFieldIds) {
        let attr = this.formAttr.find((item) => {
          return item.fieldId === filterFieldIds[reltaion].fieldid;
        });
        if (
          attr !== undefined &&
          this.formData[attr.prop] !== null &&
          Array.isArray(this.formData[attr.prop])
        ) {
          values.push(this.formData[attr.prop].join(";"));
        } else if (attr !== undefined && this.formData[attr.prop] !== null) {
          values.push(this.formData[attr.prop]);
        }
      }
      this.$refs.searchTable.filterConditionVals = values;
    },
    // 计费类型选择事件
    // 计费
    selectBillingChange() {
      this.ruleForm.settlement_type = this.$i18n.t("label.tabpage.yesz"); //是
      this.formitemloading[3]=true
      this.confirmSave('ruleForm',3);
      this.visible = false;
    },
    // 不计费
    noChargeChange() {
      this.ruleForm.settlement_type = this.$i18n.t("label.tabpage.noz"); //否
      this.formitemloading[3]=true
      this.confirmSave('ruleForm',3);
      this.visible = false;
    },
    // 处理日期时间格式
    selectDate(dateData) {
        if(dateData&&dateData!==null){
        this.isShowButton = true;
        let date = new Date(dateData);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;
        const time = y + "-" + m + "-" + d;
        this.ruleForm.duedate = time;
        // return this.ruleForm.duedate;
        this.formitemloading[0]=true
        this.confirmSave('ruleForm',0)
      }
    },
    // 实际工作时长
    actualWorkingHours() {
      this.isShowButton = true;
      this.workingtimelong=true;
    },
    actualWorkingH() {
      if (this.ruleForm.actual_working_hours) {
        let xiaoshu = this.ruleForm.actual_working_hours.split(".")[1];
        if ((xiaoshu&&xiaoshu.length == 0) || !xiaoshu) {
          this.ruleForm.actual_working_hours =
            this.ruleForm.actual_working_hours.split(".")[0];
             if(this.workingtimelong&&this.worktiemchange){
               this.formitemloading[1]=true
              this.confirmSave('ruleForm',1);
              this.workingtimelong=false;
              this.worktiemchange=false
            }
        }else{
          if(this.workingtimelong&&this.worktiemchange){
            this.formitemloading[1]=true
              this.confirmSave('ruleForm',1);
              this.workingtimelong=false;
              this.worktiemchange=false
            }
        }
      }
     
    },
    myFunction(value) {
      // if (value.replace(/[^\d]/g, "") !== "") {
      //   if (value == "0") {
      //     this.$message.error(this.$i18n.t("label.field.input.number")); //请输入正确的数字
      //     this.ruleForm.actual_working_hours = "";
      //   } else if (Number(value.replace(/[^\d]/g, "")) > 24) {
      //     this.$message.error(
      //       this.$i18n.t("label.projectManagement.must.be.less.than.24.hours") //必须小于24小时
      //     );
      //     this.ruleForm.actual_working_hours = "";
      //   }
      // } else if (value.replace(/[^\d]/g, "") == "") {
      //   this.$message.error(this.$i18n.t("label.field.input.number")); //请输入正确的数字
      //   if (Number(value.replace(/[^\d]/g, "")) === 0) {
      //     this.ruleForm.actual_working_hours = "";
      //   }
      // }
      if (isNaN(value)) {
        this.$message.error(this.$i18n.t("label.field.input.number"));
        this.ruleForm.actual_working_hours = "";
      } else {
        if (Number(value) < 0 || Number(value) > 24) {
          this.$message.error(this.$i18n.t("label.field.input.number")); //请输入正确的数字
          this.ruleForm.actual_working_hours = "";
        } else {
          let xiaoshu = value.split(".")[1];
          if (xiaoshu) {
            if (xiaoshu.length > 2) {
              this.$message.error(this.$i18n.t("label.field.input.number"));
              this.ruleForm.actual_working_hours =
                value.split(".")[0] + "." + xiaoshu.substring(0, 2);
            } else {
              this.ruleForm.actual_working_hours = value;
            }
          }
        }
      }
    },
    inputRemarkEvent() {
      this.isShowButton = true;
      this.worktotal=true;
    },
    inputblurEvent(){
      if(this.worktotal&&this.remarkschange){
        this.worktotal=false;
        this.remarkschange=false;
        this.formitemloading[4]=true
        this.confirmSave('ruleForm',4);
      }
    },
    //顶部项目名称和成员名称想入详情页
    toDetail(row){
      // if(this.isGoProjectDetial){
        const newPage = this.$router.resolve({
          path: `/commonObjects/project-detail/${row}/DETAIL`,
          query:{
            ischeck:1,
            startDate:this.$route.query.startDate,
            endDate:this.$route.query.endDate,
            activeTabNow:this.$route.query.activeTabNow
          }
        });
        window.open(newPage.href, "_blank");
      // }
    },
    ebFn(offsetHeight) {
      this.dialogBodyHeight = `${offsetHeight - 248}px`;
    }
  },
  beforeDestroy(){
    this.$bus.$off("windowResize", this.ebFn);
  },
  mounted() {
    this.$bus.$on("windowResize", this.ebFn);
    // 点击列表第一次进详细页获取页面数据，并赋值
    this.findTimeBucket();
    if (this.$route.query.state == 1) {
      let row =
        typeof this.$route.query.row == "string"
          ? typeof JSON.parse(this.$route.query.row) == "string"
            ? JSON.parse(JSON.parse(this.$route.query.row))
            : JSON.parse(this.$route.query.row)
          : this.$route.query.row;
      // this.ruleForm.taskOrProblem = row.task_or_problem;
      this.ruleForm.taskOrProblem = row.taskOrProblem;
      this.ruleForm.project_name = row.projectId; //项目id
      this.ruleForm.id = row.id;
      this.projectName = row.projectName;
      this.ruleForm.remarks = row.remarks; //备注
      this.ruleForm.duedate = row.pdate; //日期
      this.ruleForm.actual_working_hours = row.actual_working_hours; //实际工作时长
      this.ruleForm.createbyname = row.createbyname; //创建者
      this.ruleForm.memberName = row.member; //成员
      this.ruleForm.member = row.judegm;
      this.ruleForm.settlement_type = row.settlement_type; //结算类型
      this.ruleForm.projectName = row.projectName;
      this.ruleForm.createdate = row.createdate; //创建时间
      this.ruleForm.lastmodifydate = row.lastmodifydate; //最后修改时间
      this.ruleForm.projectDataType =
        row.subtaskId == null ? (row.theirProblem == null ? "1" : "3") : "2";
      this.ruleForm.name = "";
      this.taskOrProblem = row.task_or_problem;
      this.getperm();
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-spinner .circular{
  width:21px !important;
  height:21px !important;
}
.mission_details {
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  //   左边的盒子
  .task_list_left {
    width: 25%;
    height: 100%;
    .grouping {
      width: 100%;
      height: 53px;
      span {
        display: inline-block;
        font-size: 14px;
        color: #080707;
        padding: 15px 0 0 30px;
      }
    }
    .task_list_data {
      width: 100%;
      height: calc(100% - 53px);
      overflow: auto;
      position: relative;
      .no_data_available {
        width: 91px;
        height: 19px;
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .exhibition_list {
        margin-bottom: 0;
      }
      .task_information {
        width: 100%;
        height: 82px;
        border-width: 1px 0px 1px 0;
        border-style: solid;
        border-color: #d8d8d8;
        .time_charging {
          padding: 5px 0 0 30px;
          display: flex;
          .content {
            width: 100%;
          }
          img,
          .icon {
            width: 14px;
            height: 14px;
            margin-top: 4px;
          }
          .time {
            padding-left: 10px;
            color: #080707;
          }
          .file {
            padding: 5px 0 0 30px;
            padding-left: 2px;
            color: #666666;
          }
          .charging {
            padding-left: 8px;
            color: #33ff66;
          }
        }
        .subtask_name_box {
          width: 95%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 3px 0 0 30px;
          span {
            color: #080707;
            font-size: 12px;
          }
        }
        .con_text {
          width: 95%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 5px 0 0 30px;
          span {
            color: #666666;
          }
        }
      }
    }
  }
  //   右边的页面
  .details_page_right {
    width: 75%;
    height: 100%;
    overflow: auto;
    .details_task_name {
      width: 100%;
      max-height: 14%;

      .details_title_box {
        display: flex;
        padding: 0px 0 0 20px;
      }
      .details_name {
        color: #080707;
        font-size: 16px;
        font-weight: bolder;
        padding-top: 10px;
      }
      .icon_img {
        flex: 1;
        justify-content: flex-end;
        display: flex;
        align-items: center;
        padding-right: 15px;
        i {
          padding-right: 10px;
          cursor: pointer;
        }
      }
      .display_content {
        display: flex;
        .head_text {
          padding: 0px 0px 3px 20px;
          padding-right: 15px;
          display: flex;
          flex: 1;
          justify-content: space-between;
          .imgs {
            display: flex;
            align-items: center;
            img,
            .icon {
              width: 19px;
              // height: 19px;
              // margin-top: -2px;
            }
            span {
              cursor: pointer;
            }
            // .xinxiIcon{
            //   // margin-top: 3px;
            // }
            .line-right::after {
              content: "";
              display: inline-block;
              height: 12px;
              width: 2px;
              background: #ccc;
              margin: 0 5px;
            }
            .ruleproject {
               cursor: pointer;
            }
          }
          span {
            display: inline-block;
            padding-left: 5px;
            // overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 93%;
          }
          .right_arrow_img {
            cursor: pointer;
            flex: 1;
            justify-content: flex-end;
            display: flex;
            align-items: center;
            .icon {
              padding-right: 10px;
            }
          }
        }
        .forward_back-off_icon {
          flex: 1;
          justify-content: flex-end;
          display: flex;
          align-items: center;
          padding: 0px 25px 0 0;
          .left_arrow_img {
            cursor: pointer;
            padding-right: 5px;
          }
        }
      }
    }
    .working_hours_charging_date {
      width: 100%;
      height: 30%;
      .middle_box {
        width: 100%;
        height: 100%;
        display: flex;
      }
      .working_hours_left {
        width: 60%;
        height: 100%;
        border: 1px solid #dddbda;
        margin-left: 20px;
        display: flex;
        .img_duration {
          height: 100%;
          padding: 36px 0 0 26px;
          .icon {
            width: 95px;
            height: 95px;
          }
          img {
            // width: 108px;
            // height: 108px;
          }
        }
        .all_the_time {
          padding: 59px 0 0 18px;
          .journal {
            font-size: 12px;
            color: #666666;
            padding-bottom: 5px;
          }
          .hours_time {
            .hours {
              font-size: 30px;
              color: #006dcc;
            }
            .hour_text {
              font-size: 12px;
              color: #080707;
            }
          }
        }
      }
      .billing_date_right {
        width: 40%;
        height: 100%;

        position: relative;
        .billing_information {
          display: flex;
          width: 90%;
          height: 45%;
          border: 1px solid #dddbda;
          margin-left: 20px;
          .gol-coin {
            padding: 15px 0 0 24px;
            .icon {
              width: 51px;
              height: 51px;
            }
          }
          .billing_text {
            padding: 18px 0 0 9px;
            .charge_type {
              font-size: 12px;
              color: #666666;
            }
            .charge_text {
              font-size: 16px;
              color: #080707;
              font-weight: bolder;
            }
          }
        }
        .date_information {
          position: absolute;
          width: 90%;
          height: 45%;
          border: 1px solid #dddbda;
          bottom: 0px;
          margin-left: 20px;
          display: flex;
          .date-img {
            padding: 15px 0 0 24px;
            .icon {
              width: 48px;
              height: 48px;
            }
          }
          .date_time {
            padding: 18px 0 0 9px;
            .date_text {
              font-size: 12px;
              color: #666666;
            }
            .time_text {
              font-size: 16px;
              color: #080707;
              font-weight: bolder;
            }
          }
        }
      }
    }
    .schedule_information {
      width: 100%;
      height: 56%;

      .time_information_table_box {
        font-size: 14px;
        font-weight: bolder;
        color: #080707;
        width: 100%;
        height: 10%;

        padding: 5px 0 0 20px;
      }
      .information_details_data {
        width: 100%;
        height: 90%;

        .detailed_tasks {
          width: 100%;
          height: 90%;

          .top_left_right {
            width: 100%;
            height: 64%;
            display: flex;
          }
          .form_box_left {
            width: 50%;
            height: 100%;

            .details_date_box {
              padding-left: 30px;
              ::v-deep .el-input--mini .el-input__inner {
                border: 0;
              }
              ::v-deep .el-form-item__label {
                height: 20px;
              }
              ::v-deep .el-col-11 {
                width: 95% !important;
              }
            }
            .dividing_line {
              width: 93%;
              height: 1px;
              border: 1px solid #dedcda;
              margin-bottom: 5px;
            }
          }
          .form_box_right {
            width: 50%;
            height: 100%;

            ::v-deep .el-input--mini .el-input__inner {
              border: 0;
            }
            .line {
              width: 96%;
              height: 1px;
              border: 1px solid #dedcda;
              margin-bottom: 5px;
            }
            ::v-deep .el-form-item__label {
              height: 20px;
            }
          }
          .remarks {
            width: 100%;
            height: 36%;
            .remarks_record {
              width: 100%;
              height: 100%;
              padding: 0px 0 0 30px;
              .remarks_text {
                font-size: 14px;
                color: #666666;
                padding-bottom: 4px;
              }
            }
            ::v-deep .el-textarea {
              width: 98%;
            }
          }
        }
        ::v-deep .el-textarea__inner {
          height: 85px;
        }
        .details_btn {
          width: 100%;
          // height: 13%;
          padding: 0px 20px 0px 0px;
          .btn {
            flex: 1;
            text-align: right;
            padding-top: 10px;
          }
        }
      }
      ::v-deep .el-form-item .is-required .el-form-item--mini {
        margin-bottom: 0px;
      }
      ::v-deep .el-form-item {
        margin-bottom: 0px;
      }
      ::v-deep .el-form--label-top .el-form-item__label {
        padding: 0;
      }
      .actual_working_hours {
        padding-left: 30px;
        ::v-deep .el-input--mini .el-input__inner {
          width: 93%;
        }
        ::v-deep .el-input--mini .el-input__inner {
          border: 0;
        }
        .line {
          width: 93%;
          height: 1px;
          border: 1px solid #dedcda;
          margin-bottom: 3px;
        }

        ::v-deep .el-form-item__label {
          height: 20px;
        }
      }
    }
  }
}
.billing_style {
  width: 38%;
  height: 50px;
  border-radius: 2px;
  border: 1px solid #c8c6c6;
  .select_billing {
    height: 25px;
    padding-left: 10px;
    cursor: pointer;
    color: #080707;
  }
  .no_charge {
    height: 25px;
    padding-left: 10px;
    cursor: pointer;
    color: #080707;
  }
  .select_billing:hover {
    background: #c8c6c6;
  }
  .no_charge:hover {
    background: #c8c6c6;
  }
}
// 选中后背景色
.dataStyle {
  background: #eaf0f4;
}
// 禁止点击样式
.prohibit {
  cursor: not-allowed;
  padding-right: 5px;
}
</style>

<style lang="scss">
.el-popover.chargingPopover {
  position: absolute;
  border: 0px;

  .select_billing {
    cursor: pointer;
    color: #080707;
  }
  .select_billing:hover {
    background: #c8c6c6;
  }
  .no_charge {
    cursor: pointer;
    color: #080707;
  }
  .no_charge:hover {
    background: #c8c6c6;
  }
}
.ruleproject:hover{
  color:#006dcc;
}
.userpopver{
  ul{
    width: 100%;
    min-height: 50px;
    max-height:300px;
    overflow: auto;
    margin-top:10px;
    .backli{
      background: #ecf5ff;
    }
    li{
      height: 30px;
      margin-top:5px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon{
        width:18px;
        height:18px;
        margin-left:5px;
        margin-right:5px;
      }
    }
    li:hover{
      background: #ecf5ff;
    }
  }
  .nodeta{
    width: 100%;
    height: 30px;
    text-align: center;
    margin-top: 10px;
  }
}
.userinput{
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>