import axios from '@/config/httpConfig'

//获取以用户分组的列表视图
export function findUserIdProjectWorkList(data) {
  return axios.post('/projectWorkList/findUserIdProjectWorkList', data)
}

//获取以日期分组的列表视图 
export function findTimeBucket(data) {
  return axios.post('/projectWorkList/findTimeBucket', data)
}

//获取展示页面时间段内的时长汇总
export function getCurveTimeBucket(data) {
  return axios.post('/projectWorkList/getCurveTimeBucket', data)
}
//获取实际工作清单网格视图
export function getGridView(data) {
  return axios.post('/projectWorkList/getGridView', data)
}

//获取对象记录权限
export function getPermissionById(data) {
  return axios.post('/objectdetail/getPermissionById', data)
}

// 删除记录
export function del(data) {
  return axios.post('/objectdetail/delete', data)
}
// 保存
export function save(data) {
  return axios.post('/objectInfo/save', data)
}
// 获取查找信息
export function getLookupInfo(data) {
  return axios.post('/lookup/getLookupInfo', data)
}
// 获取查找数据
export function getLookupData(data) {
  return axios.post('/lookup/getLookupData', data)
}